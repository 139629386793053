body {
	overflow-y: hidden;
}
.login-in-page {
	height: 100vh;
}

.login-in-page-bg {
	position: relative;
	background-image: url("../../public/Images/prestige-img.jpg");
	background-size: cover;
	background-position: center;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.login-in-page-bg::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
}

.login-in-from {
	padding-top: 15px;
	background: rgba(255, 255, 255, 0.8);
	padding: 50px;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.ant-form-item-control-input-content {
	text-align: center !important;
}

.button-login-p button {
	text-align: center;
	width: 40%;
	background-color: #05337e !important;
	border-color: #01235a !important;
	color: white;
}

.button-login-p button:hover {
	background-color: #01235a !important;
	border-color: #01235a !important;
}
.prestige-heading {
	font-style: italic;
	color: #05337e;
	margin-bottom: 18px;
	text-align: center;
}
.dashboard .ant-select,
.dashboard .ant-btn {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	border-radius: 15px;
	height: 40px;
}
